<template>
  <div class="main" style="margin: 10px;">
    <div class="container" style="height: 55px;" @click="goHome()">
      <div class="image2">
        <el-image :src="'./images/header-log.png'" />
      </div>
      <div class="image1" v-if="!isNullValue(store.state.packageResp.data)" >
        <el-icon :size="35"><ArrowLeft /></el-icon>
      </div>
    </div>
    <!-- 查询条件 -->
    <el-row align="middle" class="el-row-margin">
      <el-col :span="18">
        <!-- <el-input
            type="text"
            :prefix-icon="Tickets"
            v-model="refIccid"
            :placeholder="`${getI18n('请输入[ICCID]', 'Please Input [ICCID]')}`"
            style="cursor: pointer"
            sytle="margin: 10px;"
        ></el-input> -->
        <el-select
          :prefix-icon="Tickets"
          v-model="refIccid"
          filterable
          clearable
          allow-create
          default-first-option
          :reserve-keyword="false"
          :placeholder="`${getI18n('请输入[ICCID]', 'Please Input [ICCID]')}`"
          class="custom-select-height"
          @change="onChange"
          @blur="onBlur"
        >
          <el-option
            v-for="item in refIccidOptions"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-col>
      <el-col :span="6" style="display: flex; justify-content: flex-end;">
        <el-button type="primary" @click="query()">
          <span class="button-font">{{getI18n('查询', 'Query')}}</span>
        </el-button>
      </el-col>
    </el-row>
    <template v-if="store.state.iccidInfoResp.code == '0' && !isNullValue(store.state.iccidInfoResp.himsis)">
      <IccidCard :iccidInfoResp="store.state.iccidInfoResp"/>
    </template>
    <template v-if="store.state.packageResp.code == '00000'">
      <template v-if="!isNullValue(store.state.packageResp.data)">
        <!-- 显示套餐数据 -->
        <template v-for="plan in store.state.packageResp.data" :key="plan.order_id">
          <PackageCard :plan="plan"/>
        </template>
      </template>
    </template>
    <template v-else>
      <div class="card bottom10px" >
        <el-row class="card-row" :gutter="20" align="middle">
            <el-col :span="24">
              <span class="warning">{{ store.state.packageResp.message }}</span>
            </el-col>
        </el-row>
        <HelpCenter/>
      </div>
    </template>
    <template v-if="isNullValue(store.state.packageResp.data)">
      <HelpCenter />
    </template>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted } from 'vue'
import {
  Tickets,
  ArrowLeft
} from '@element-plus/icons-vue'
import store from '../store'
import { apiPostData, isNullValue, getI18n, setCookie, getCookie } from '../utils'
import HelpCenter from '../components/HelpCenter.vue'
import PackageCard from '../components/PackageCard.vue'
import IccidCard from '../components/IccidCard.vue'

export default defineComponent({
  name: 'QueryOrderView'
})
</script>

<script lang="ts" setup>

// const refIccid = ref('89852342022040149139')
// const refIccid = ref('89852342022100442002')
const refIccid = ref('')
const refIccids = ref([])
const refIccidOptions = ref([])

onMounted(async () => {
  const iccids = getCookie('iccids')
  if (iccids) {
    refIccids.value = iccids.split(',')
  }
  if (refIccids.value.length >= 1) {
    refIccid.value = refIccids.value[0]
  }
  refIccidOptions.value = [...refIccids.value]
  refIccidOptions.value.push(getI18n('清空', 'clear'))
})

function onChange (value: string) {
  console.log('onChange value=', value)
  if (value === getI18n('清空', 'clear')) {
    refIccids.value = []
    refIccidOptions.value = [getI18n('清空', 'clear')]
    setCookie('iccids', '', 0)
    refIccid.value = ''
  }
}
function onBlur (event: FocusEvent) {
  refIccid.value = event.target.value
}

function query () {
  queryIccidInfo()
  queryUserPlan()
}

function queryUserPlan () {
  const data = { iccid: refIccid.value }
  apiPostData('/cmi/query-user-plan', data)
    .then(data => {
      if (data !== null && data !== undefined) {
        store.state.packageResp = data
        if (data.code === '00000') {
          let isHas = false
          for (const item of refIccids.value) {
            console.log(item.trim()) // 输出每个元素，并去掉两端的空格
            if (refIccid.value === item) {
              isHas = true
            }
          }
          if (!isHas) {
            refIccids.value.push(refIccid.value)
          }

          const iccids = refIccids.value.join(',')
          setCookie('iccids', iccids, 7)

          refIccidOptions.value = [...refIccids.value]
          refIccidOptions.value.push(getI18n('清空', 'clear'))
        }
      }
    })
}
function queryIccidInfo () {
  const data = { iccid: refIccid.value }
  apiPostData('/cmm/v1/query-sim-info', data)
    .then(data => {
      if (data !== null && data !== undefined) {
        store.state.iccidInfoResp = data
      }
    })
}
function goHome () {
  store.state.packageResp = { code: '00000' }
  store.state.iccidInfoResp = { code: '00000' }
}
</script>

<style scoped>
.button-font {
  color: white;
  font-weight: bold;
}
.container {
  position: relative;
}
.image1,
.image2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  align-items: center;
  display: grid;
}
.el-row-margin {
  margin: 10px;
}
.el-button {
  display: flex;
  justify-content: flex-end;
  height: 40px;
}
.warning {
  color: red;
  font-weight: bold;
  font-size: 24px;
}
/* 设置自定义类的样式 */
.custom-select-height {
    cursor: pointer;
    width: 100%;
  }
@media screen and (min-width: 600px) {
  .main {
    max-width: 600px; /* 或根据需要设置为其他宽度 */
    width: 600px;
    margin: 0 auto; /* 使内容居中 */
    padding: 0 10px; /* 给两边留一些空白 */
  }

  /* 设置自定义类的样式 */
  .custom-select-height {
    height: 40px;
  }
  .custom-select-height ::v-deep .el-input__inner {
    font-size: 24px;
    height: 40px;
  }
  .button-font {
    font-size: 24px;
  }
}
</style>
